import React from "react"
import SEO from "../components/seo"
import MainLayout from "../layouts/mainLayout"

interface LocationProps {
  location: Location;
}

const NotFoundPage: React.FC<LocationProps> = (props) => (
  <MainLayout>
    <SEO title="404: Not found" location={props.location}/>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </MainLayout>
)

export default NotFoundPage
